/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { Colors, FontWeight } from '../../constants/theme'

export const StyledCustomAutoCompleteContainer = styled.div`
  position: relative;
  margin: 10px 0 0px 0;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 100%;
    max-width: 570px;
  }
`

export const StyledHero = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  padding-bottom: 70px;
  padding-top: max(12vh, 80px);
  min-height: 650px;
  max-height: 850px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 100px;
    height: calc(100vh - 0px);
    min-height: 600px;
    max-height: initial;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 100vh;
    min-height: 730px;
  }
`

export const StyledHeroContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
    justify-content: inherit;
  }
`

export const StyledDynamicText = styled.span`
  color: ${Colors.primary};
  width: 5.5em;
  margin: 0px 0px;
  border-radius: 8px;
  text-decoration: none;

  &::after {
    content: '|';
  }

  :hover {
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
    border-radius: 16px;
    margin: 5px 0px;
  }
`

export const StyledImageContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
    height: 43vh;
    min-height: 290px;
    max-height: 480px;

    & > img {
      margin-top: -30px;
      padding-left: 0px;
      width: 110% !important;
      height: 110% !important;
    }

    & > div {
      width: 75%;
      border-top-left-radius: 300px;
      border-bottom-left-radius: 260px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 40%;
    height: 75vh;

    & > div {
      width: 80%;
      border-top-left-radius: 300px;
      border-bottom-left-radius: 260px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 50%;
    max-width: 700px;
  }
`

export const StyledBlueBackground = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100%;
  background-color: ${Colors.grey300};

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: auto;
    bottom: 0;
  }
`

export const StyledMainTitle = styled.h1`
  color: ${Colors.black400};
  font-size: 50px !important;
  line-height: 50px !important;
  text-align: left !important;
  font-weight: ${FontWeight.semiBold};
  margin: 5px 0px;
  padding-top: 15px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 60px !important;
    line-height: 65px !important;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 65px !important;
    line-height: 70px !important;
  }

  ${({ theme }) => theme.breakpoints.up('1300')} {
    font-size: 75px !important;
    line-height: 80px !important;
  }
`

export const StyledSubtitle = styled.h2`
  color: ${Colors.black400};
  text-align: left !important;
  font-size: 20px;
  line-height: 24px;
  font-weight: ${FontWeight.regular};
  margin: 10px 0px;
  padding: 0px 10px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    text-align: center;
    width: 100%;
    padding: 0px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 23px;
    line-height: 28px;
    margin: 30px 0px;
    text-align: left;
    max-width: 500px;
  }
`

export const StyledTitleContainer = styled.div<any>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 0 20px;
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
    padding-left: 50px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 80px;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    padding-left: 100px;
  }
  ${({ theme }) => theme.breakpoints.up('1400')} {
    padding-left: 150px;
  }
`
